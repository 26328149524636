<template>
  <v-container fluid pa-0 ma-0>
    <v-layout row wrap max-width-1400 full-width ma-auto>
      <v-flex xs12 py-5 text-xs-center>
        <p class="graphik-medium secondary--text" :class="{'font-36': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_how_it_works_title') }}</p>

        <v-layout row wrap>
          <v-flex xs12 sm4 v-for="(step, index) in how_it_works" :key="index" class="px-5">
            <v-img
              :aspect-ratio="1"
              contain
              class="ma-auto mb-2"
              position="center bottom"
              :src="step.icon"
              :lazy-src="step.icon"
              width="180px"
            ></v-img>

            <p class="graphik-bold secondary--text" :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-18': $vuetify.breakpoint.xsOnly}">{{ step.title }}</p>

            <p class="graphik-light mb-4 mx-auto" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ step.description }}</p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContentHowItWorksManual',
  data () {
    return {
      how_it_works: [
        {
          title: this.$ml.get('content_how_it_works_step_4'),
          description: this.$ml.get('content_how_it_works_step_4_description'),
          icon: require('@/assets/img/images/how_it_works_4.png')
        },
        {
          title: this.$ml.get('content_how_it_works_step_5'),
          description: this.$ml.get('content_how_it_works_step_5_description'),
          icon: require('@/assets/img/images/how_it_works_5.png')
        },
        {
          title: this.$ml.get('content_how_it_works_step_3'),
          description: this.$ml.get('content_how_it_works_step_3_description'),
          icon: require('@/assets/img/images/how_it_works_3.png')
        }
      ]
    }
  }
}
</script>
